import { common } from './config.common'

export const config = {
  ...common,
  environment: 'staging',
  baseUrl: 'https://staging.mediuutiset.media',
  apiRoot: 'https://staging.mediuutiset.media/api/',
  assetUrl: 'https://staging.mediuutiset.media',
  cookieDomain: '.mediuutiset.media',
  contentRouterUrl:
    'https://s3-eu-west-1.amazonaws.com/test-content-router-data/',
  imageServiceUrl: 'https://images.almatalent.fi',
  changePasswordUrl: 'https://tunnus.almatalent.fi/unohtunut-salasana/',
}
